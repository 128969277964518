<template>

    <div class="bigBox">
        <div class="zkxmTopBox">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/Icon(1)_1686298472325.png" class="backIcon" @click="toBack">
            <p class="zkxmTopBoxTitle">我的收藏</p>
        </div>
        <div style="height:.88rem"></div>


        <div class="zkxmList" v-if="moviefrom[0]">

            <div class="movieitem" v-for="(item) in moviefrom" :key="item.squarePic">
                <router-link :to="{path:`/moviedetail/${item.projectId}`}">

                    <div class="movieitmebox">
                        <div class="movieitemright">
                            <img :src="item.squarePic">
                        </div>
                        
                        <div class="movieitemleft">
                            <div class="moviep">{{item.columnId_dictText}}</div>
                            <div class="movietitle">{{item.projectTitle}}</div>
                            <div class="movietime">
                                <span class="movietimeauthor">{{item.infoSource}}</span>
                                <div class="movietimedian"></div>
                                <span>{{item.pushTime | dataFilter}}</span>
                            </div>
                        </div>
                        
                    </div>
                    
                </router-link>
            </div>


        </div>

        <div v-else class="noData">
            暂无项目
        </div>



    </div>



</template>

<script>
import Cookies from 'js-cookie'
import {getListColumn,getChildColumn,getArticleAndProject,getListBanner,getlistOnHomepage,getlistOnHomepage2,sms,phoneRegister,listInterestProject} from "@/api/index"
import { Loading ,Message } from 'element-ui';
import store from '@/store'

export default {

    data(){
        return {
            load : null,
            moviefrom: [],
            wjsToken: null,
            wjsUserInfo: null,
            total:null,
            pageSize:8,
            currPageNo:1,
            articleload:true,
            t : null,
            clientHeight : null,
            scrollHeight : null,
        }
    },
    methods:{

        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },

        showErr(txt){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: txt || '网络错误',
                spinner: 'el-icon-circle-close',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                this.load && this.load.close(); 
            },2000)
        },

        showSuccess(txt){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: txt || '成功',
                spinner: 'el-icon-circle-check',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                this.load && this.load.close(); 
            },2000)
        },

        toBack(){
            this.$router.back(-1)
        },

        toLink(name){
            // if(name == "yszb"){
            //     //window.location.href="http://www.zygart.cn"; 
            // }else{
                //this.reset()
                this.$router.push({name:name});
            //}
        },


        windowscroll(){
            this.t = document.documentElement.scrollTop || document.body.scrollTop;
            this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
            this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

            if(this.scrollHeight > this.clientHeight && this.t + this.clientHeight + 200  >= this.scrollHeight) {
                if(this.articleload && this.moviefrom.length && this.moviefrom.length < this.total){

                    this.showLoading()
                    this.articleload = false;
                    this.currPageNo += 1;

                    listInterestProject({
                        "data": {},
                        "page": {
                            "currPageNo": this.currPageNo,
                            "pageSize": this.pageSize
                        }
                    }).then(res => {
                        this.moviefrom = this.moviefrom.concat(res.result.records)
                        this.total = res.result.total;
                        this.load && this.load.close();
                    })

                    setTimeout(() => {
                        this.articleload = true;
                    },3000)

                    
                }
                
            }
        },

            

    },
    mounted(){

        this.wjsToken =  Cookies.get('wjsToken') || null;
        this.wjsUserInfo =  Cookies.get('wjsUserInfo') || null;
        this.wjsUserInfo = JSON.parse(this.wjsUserInfo);

        console.log(this.wjsToken)
        console.log(this.wjsUserInfo)

        if(!this.wjsToken){
            this.showErr('请先登录')
            this.toLink('dl')
        }

        this.showLoading()

        this.t = document.documentElement.scrollTop || document.body.scrollTop;
        this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        window.addEventListener('scroll',this.windowscroll)

        listInterestProject({
            "data": {},
            "page": {
                "currPageNo": this.currPageNo,
                "pageSize": this.pageSize
            }
        }).then(res => {
            this.moviefrom = res.result.records;
            this.total = res.result.total;
            this.load && this.load.close();
        })



    },
    filters: {
        movieTypeFilters: function (value) {
            if (!value) return ''
                switch(value){
                case 1:
                    return "院线电影"
                    break;
                case 2:
                    return "网络电影"
                    break;
                default:
                    return "error"
            }
        },
        shootStatusFilters: function (value) {
            if (!value) return ''
                switch(value){
                case 1:
                    return "未上映"
                    break;
                case 2:
                    return "已上映"
                    break;
                default:
                    return "error"
            }
        },
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    },

}




</script>





<style lang="scss" scoped>
.bigBox{
    width: 100%;
    height: 100%;
    background-color: #fff;

    .zkxmTopBox{
        height: .88rem;
        width: 100%;
        position: relative;
        position: fixed;
        top: 0;
        z-index: 999;
        background-color: #fff;
        .backIcon{
            height: .48rem;
            width: .48rem;
            position: absolute;
            top: .2rem;
            left: .4rem;
        }
        .zkxmTopBoxTitle{
            font-size: .34rem;
            color: #343434;
            text-align: center;
            line-height: 0.88rem;
        }
    }

    .zkxmList{
        background: #F6F6F6;
        padding: .1rem .22rem;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        width: 100%;
        .movieitem{
            width: 100%;
            padding: 0.2rem 0.22rem 0.2rem;
            border-bottom: 1px solid #E6E6E7;
            box-sizing: border-box;
            border-radius: .08rem;
            background-color: #FFFFFF;
            margin-bottom: .1rem;
            .movieitmebox{
                width: 100%;
                height: 100%;
                display: flex;

                .movieitemright{
                    width: 2.42rem;
                    height: 2.42rem;
                    overflow: hidden;
                    border-radius: 0.12rem;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }


                .movieitemleft{
                    width: calc(100% - 2.42rem - 0.4rem);
                    margin-left: .4rem;
                    position: relative;
                    overflow: hidden;
                    .moviep{
                        font-size: .28rem;
                        font-weight: 400;
                        color: #8D8D8E;
                        line-height: .36rem;
                        margin-bottom: .22rem;
                    }
                    .movietitle{
                        font-size: 0.32rem;
                        font-weight: 400;
                        line-height: 0.4rem;
                        color: #2D2D2F;
                        margin-bottom: 0.1rem;
                        display: -webkit-box;    
                        -webkit-box-orient: vertical;    
                        -webkit-line-clamp: 3;    
                        overflow: hidden;

                    }
                    .movietime{
                        font-size: 0.28rem;
                        font-weight: 400;
                        line-height: 0.36rem;
                        color: #B8B8B8;
                        white-space: nowrap;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        display: flex;
                        overflow: hidden;
                        .movietimeauthor{
                            color: #A30001;
                            margin-right: .2rem;
                        }
                        .movietimedian{
                            width: .08rem;
                            height: .08rem;
                            background-color: #8D8D8E;
                            border-radius: 50%;
                            margin-right: .2rem;
                            margin-top: .12rem;
                        }
                    }
                }
            
            
                
            }
            
        }


    }





}


.noData{
    font-size: .4rem;
    text-align: center;
    line-height: 3rem;
}

</style>